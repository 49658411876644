import React from "react"

const defaultState = {
    site: 'senergy',
    changeSite: () => { },
}
const SiteContext = React.createContext(defaultState)

class SiteProvider extends React.Component {
    state = {
        site: 'masteremaco',
    }
    changeSite = (site) => {
        localStorage.setItem("site", JSON.stringify(site))
        this.setState({ site })        
    }
    componentDidMount() {
        // Getting dark mode value from localStorage!
        const lsSite = JSON.parse(localStorage.getItem("site"))
        if (lsSite) {
            this.setState({ site: lsSite })
        }
    }
    render() {
        const { children } = this.props
        const { site } = this.state
        return (
            <SiteContext.Provider
                value={{
                    site,
                    changeSite: this.changeSite,
                }}
            >
                {children}
            </SiteContext.Provider>
        )
    }
}
export default SiteContext
export { SiteProvider }