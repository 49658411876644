import React, { Component } from 'react';

class PageHeader extends Component {

    constructor(props) {
        super(props);
        this.state = {
            imgUrl: '',
            assets: [],
        }
        // this.setImgUrl = this.setImgUrl.bind(this);
    }

    // setImgUrl(imgUrl) {
    //     this.setState({
    //         imgUrl,
    //     })
    // }

    render() {
        // const assets = this.props.data.craft.assets
        // const [ pageHeader ] = assets.filter(asset => asset.filename === 'App-Header_7.JPG')
        return (
            <div></div>
            // <div className="page-header">
            //     <div className="page-header__image quote-tool"></div>
            // </div>
        )
    }
}

export default PageHeader