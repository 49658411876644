/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import SiteContext from '../context/SiteContext'

import Header from "./header"
import Footer from "./Footer"
import PageHeader from "./PageHeader"
import "../styles/layout.scss"

const Layout = ({ children }) => {
    const data = useStaticQuery(graphql`
        query SiteTitleQuery {
            site {
                siteMetadata {
                    title
                }
            }
        }
    `)

    let basfColours = {
        sikaYellow: '#ffc510',
        basfOrange: '#F39500',
        basfOrangeLightened: '#FACF8C',
        basfBlue: '#00509D',
        basfRed: '#C50022',
        basfGreen: '#99C24D',
        buttonGreen: '#99C24D',
    }

    const theme = createMuiTheme({
        overrides: {
            // Name of the component ⚛️ / style sheet
            MuiOutlinedInput: {
                root: {
                    color: '#666',
                    fontSize: 16,
                    '&$focused $notchedOutline': {
                        borderColor: basfColours.sikaYellow,
                        borderWidth: 2,
                    },
                    '& $notchedOutline': {
                        borderColor: basfColours.sikaYellow,
                        borderWidth: 2,
                        borderRadius: 0,
                    },
                    "&:hover:not($disabled):not($focused):not($error) $notchedOutline": {
                        borderWidth: 2,
                        borderColor: basfColours.basfOrangeLightened,
                    },
                    '&$error $notchedOutline': {
                        borderColor: basfColours.sikaYellow,
                    },
                }, // end root
            }, // end MuiOutlinedInput
            MuiInputLabel: {
                root: {
                    color: basfColours.sikaYellow,
                    fontSize: 15,
                    '&$focused': {
                        color: basfColours.sikaYellow,
                    },
                },
            }, // end MuiInputLabel
            MuiFormLabel: {
                root: {
                    color: '#666',
                    fontSize: 15,
                    paddingBottom: 10,
                    '&$focused': {
                        color: '#666',
                    },
                }
            }, //end MuiFormLabel
            MuiInputBase: {
                root: {
                    fontSize: 15,
                    color: '#666',
                },
                input: {
                    backgroundColor: '#fff',
                },
            }, //end MuiInputBase
            MuiSvgIcon: {
                root: {
                    marginLeft: 10,
                    marginRight: 10,
                },
                colorPrimary: {
                    color: basfColours.sikaYellow,
                },
                colorSecondary: {
                    color: '#FFF',
                },
            }, // end MuiIcon
            MuiButton: {
                // Name of the rule
                label: {
                    color: '#000',
                },
                text: {
                    // Some CSS
                    backgroundColor: basfColours.sikaYellow,
                    borderRadius: 0,
                    border: 0,
                    color: 'white',
                    fontSize: 15,
                    fontFamily: 'Klavika',
                    height: 56,
                    padding: '0 20px',
                    boxShadow: 'none',
                    fontWeight: '600',
                    '&:hover': {
                        backgroundColor: basfColours.basfOrangeLightened
                    }
                },
                outlinedPrimary: {
                    color: basfColours.sikaYellow,
                    borderRadius: 0,
                    borderColor: basfColours.sikaYellow,
                    borderWidth: 2,
                    backgroundColor: 'transparent',
                    fontSize: 13,
                    height: 56,
                    padding: '0 20px',
                    boxShadow: 'none',
                    fontWeight: '400',
                    '&:hover': {
                        borderColor: 'RGBA(26, 46, 71, 1.00)',
                        color: 'RGBA(26, 46, 71, 1.00)',
                        borderWidth: 2,
                        backgroundColor: 'transparent',
                    },
                },
                containedPrimary: {
                    color: '#000',
                    backgroundColor: basfColours.sikaYellow,
                    borderRadius: 0,
                    border: 0,
                    fontSize: 13,
                    height: 56,
                    padding: '0 20px',
                    boxShadow: 'none',
                    fontWeight: '400',
                    marginBottom: 5,
                    '&:hover': {
                        backgroundColor: '#DC667A',
                    },
                },
                containedSecondary: {
                    color: '#000',
                    backgroundColor: basfColours.basfGreen,
                    borderRadius: 0,
                    border: 0,
                    fontSize: 13,
                    height: 56,
                    padding: '0 20px',
                    boxShadow: 'none',
                    fontWeight: '400',
                    '&:hover': {
                        backgroundColor: '#B7EA58',
                    },
                },
            }, // end MuiButton
            MuiExpansionPanel: {
                root: {
                    // backgroundColor: '#F5F5F5',
                    border: 'none',
                    borderBottom: 'solid 2px #c50022',
                    boxShadow: 'none',
                    lineHeight: 1.2,
                    color: '#000',
                },
                '&$expanded': {
                    margin: '0',
                },
            }, // end MuiExpansionPanel
            MuiExpansionPanelSummary: {
                root: {
                    backgroundColor: '#fff',
                    padding: 0,
                    '&$expanded': {
                        minHeight: 'auto',
                    },
                },
                '&$expanded': {
                    // backgroundColor: '#DC667A',
                },
                expandIcon: {
                    color: basfColours.sikaYellow,
                },
            }, // end MuiExpansionPanelSummary
            MuiExpansionPanelDetails: {
                root: {
                    padding: '24px 0',
                    width: '100%',
                }
            }, //end MuiExpansionPanelDetails
            MuiTypography: {
                body1: {
                    fontSize: 15,
                    color: '#666',
                    width: '100%',
                },
                body2: {
                    fontSize: 18,
                    color: '#666',
                    width: '100%',
                },
                h3: {
                    fontSize: 18,
                    color: basfColours.sikaYellow,
                    fontWeight: 700,
                },
                h4: {
                    fontSize: 12,
                    color: '#fff',
                    textTransform: 'capitalize',
                    width: '100%'
                },
            }, // end MuiTypography
            MuiFormControl: {
                root: {
                    width: '100%',
                },
            }, //end MuiFormControl
            MuiFormGroup: {
                root: {
                    paddingBottom: 15,
                }
            }, //end MuiFormGroup
            MuiFormControlLabel: {
                fontSize: 100,
            },
            MuiRadio: {
                root: {
                    color: basfColours.sikaYellow,
                    fontSize: 34,
                },
                primary: {
                    color: basfColours.sikaYellow,
                },
                colorPrimary: {
                    '&$checked': {
                        color: basfColours.sikaYellow,
                    },
                    '&$disabled': {
                        color: '#ddd',
                    }
                },
            },
            MuiDialog: {
                paper: {
                    paddingTop: 30,
                    paddingBottom: 30
                }
            }, // end MuiDialog
            MuiDialogActions: {
                root: {
                    paddingTop: 0,
                    paddingBottom: 0,
                    paddingLeft: 20,
                    paddingRight: 20
                }
            }, // end MuiDialogActions
            MuiFab: {
                root: {
                    backgroundColor: basfColours.sikaYellow,
                    boxShadow: 'none',
                    color: '#fff',
                    '&:hover': {
                        backgroundColor: '#DC667A',
                    },
                },
                primary: {
                    backgroundColor: 'transparent',
                    color: basfColours.sikaYellow,
                    '&:hover': {
                        backgroundColor: '#DC667A',
                    }
                },
                secondary: {
                    backgroundColor: 'transparent',
                    color: basfColours.basfGreen,
                    '&:hover': {
                        backgroundColor: '#B7EA58',
                    }
                },
                cancel: {
                    backgroundColor: 'transparent',
                    color: basfColours.sikaYellow,
                    '&:hover': {
                        backgroundColor: '#DC667A',
                    }
                },
            }, // end MuiFab
        },
        typography: { useNextVariants: true },
    });

    return (
        <MuiThemeProvider theme={theme}>
            <SiteContext.Consumer>
                {site => (
                    <div>
                        <Helmet>
                            <meta name="apple-mobile-web-app-status-bar-style" content="default" />
                            {/* <title>{this.props.location.pathname === "/"}</title> */}
                            {/* <meta name="description" content="My Site" /> */}
                        </Helmet>
                        <Header />
                        <PageHeader />
                        <div>
                            <main>
                                {children}
                                <Footer />
                            </main>
                        </div>
                    </div>
                )}
            </SiteContext.Consumer>
        </MuiThemeProvider>
    )
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
}

export default Layout
