import React, { Component } from 'react'
import { AiFillTwitterSquare } from "react-icons/ai";
import { AiFillFacebook } from "react-icons/ai";
import { AiFillLinkedin } from "react-icons/ai";
import { AiFillYoutube } from "react-icons/ai";
import { AiOutlineInstagram } from "react-icons/ai";
import SikaLogo from '../images/sika-logo-1.png'

class Footer extends Component {
    
    constructor(props) {
        super(props)
        this.state = {
            language: '',
            region: '',
            languageText: '',
            selectedLanguageOption: '',
            selectedRegionOption: '',
            regionOptions: [],
            showRegionPicker: false,
            languageCode: '',
        }
    }

    componentDidMount() {
        const { locale } = this.context
    }

    render() {
        return (
            <footer class="footer">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="footer__content">
                                <div class="row">
                                    <div id="ctl00_ctl34_companyContainer" class="col-md-2 col-sm-4 noindex">
                                        <nav class="footer__nav">
                                            <p class="h3 footer__title">About Sika</p>
                                            <ul class="panel-content">
                                                <li id="ctl00_ctl34_listEntryAboutUs">
                                                    {/* <a href="https://mbcc.sika.com/en-us/about-us" target="_blank" rel="nofollow noopener" id="ctl00_ctl34_lnkAboutUs">About</a> */}
                                                    <a href="https://usa.sika.com/en/about-us/history-of-sika.html" target="_blank" rel="nofollow noopener">History of Sika</a>
                                                </li>
                                                <li id="ctl00_ctl34_listEntryNewsAndEvents">
                                                    {/* <a href="https://mbcc.sika.com/en-us/about-us/news" target="_blank" rel="nofollow noopener" id="ctl00_ctl34_lnkNewsAndEvents">News, Events and Media</a> */}
                                                    <a href="https://usa.sika.com/en/about-us/sika-acquisitions.html" target="_blank" rel="nofollow noopener" id="ctl00_ctl34_lnkNewsAndEvents">Sika Acquisitions</a>
                                                </li>
                                                <li id="ctl00_ctl34_listEntryProductOverview">
                                                    {/* <a href="https://mbcc.sika.com/en-us/product-overview" target="_blank" rel="nofollow noopener" id="ctl00_ctl34_lnkProductOverview">Product overview</a> */}
                                                    <a href="https://usa.sika.com/en/about-us/sika-business-units.html" target="_blank" rel="nofollow noopener">Sika Business Units</a>
                                                </li>
                                                <li id="ctl00_ctl34_listEntryGlobal">
                                                <a href="https://usa.sika.com/en/about-us/sika-usa-management-team.html" target="_blank" rel="nofollow noopener">Sika USA Management Team</a>
                                                </li>
                                                <li>
                                                <a href="https://usa.sika.com/en/resource-center/sika-usa-news.html" target="_blank" rel="nofollow noopener">News</a>
                                                </li>
                                                <li>
                                                <a href="https://usa.sika.com/en/resource-center/sika-usa-events.html" target="_blank" rel="nofollow noopener">Events</a>
                                                </li>
                                            </ul>
                                        </nav>
                                    </div>
                                    <div id="ctl00_ctl34_servicesContainer" class="col-md-2 col-sm-4 noindex">
                                        <nav class="footer__nav">
                                            <p class="h3 footer__title">More Info</p>
                                            <ul class="panel-content">
                                                <li id="ctl00_ctl34_listEntryContact">
                                                    {/* <a href="https://mbcc.sika.com/en-us/contact" target="_blank" rel="nofollow noopener" id="ctl00_ctl34_lnkContact">Contact</a> */}
                                                    <a href="https://mbcc.sika.com/en-us/contact" target="_blank" rel="nofollow noopener">Contact Us</a>
                                                </li>
                                                <li id="ctl00_ctl34_listEntryContactPersons">
                                                    {/* <a href="https://mbcc.sika.com/en-us/contact/persons" target="_blank" rel="nofollow noopener" id="ctl00_ctl34_lnkContactPersons">Contact persons</a> */}
                                                    <a href="https://usa.sika.com/en/about-us/sika-usa-locations.html" target="_blank" rel="nofollow noopener">Locations</a>
                                                </li>
                                                <li id="ctl00_ctl34_listEntryDownloads">
                                                    {/* <a href="https://mbcc.sika.com/en-us/download-area" target="_blank" rel="nofollow noopener" id="ctl00_ctl34_lnkDownloads">Downloads</a> */}
                                                    <a href="https://usa.sika.com/en/careers.html" target="_blank" rel="nofollow noopener">Careers</a>
                                                </li>
                                                <li id="ctl00_ctl34_listEntryUserLogin">
                                                    {/* <a href="https://mbcc.sika.com/en-us/_layouts/15/Authenticate.aspx?Source=https://online-tools.master-builders-solutions.com/" target="_blank" rel="nofollow noopener" id="ctl00_ctl34_lnkLogin"><i class="fa fa-lock fa-icon-medium"></i>&nbsp;Login</a> */}
                                                    <a href="https://usa.sika.com/en/resource-center/continuing-education-library.html" target="_blank" rel="nofollow noopener">Continuing Education Library</a>
                                                </li>
                                                <li>
                                                    <a href="https://usa.sika.com/en/resource-center/sika-every-day-app.html" target="_blank" rel="nofollow noopener">Sika Every Day App</a>
                                                </li>
                                                <li>
                                                    <a href="https://usa.sika.com/en/sustainability.html" target="_blank" rel="nofollow noopener">Sustainability</a>
                                                </li>
                                            </ul>
                                        </nav>
                                    </div>
                                    <div id="ctl00_ctl34_socialMediaContainer" class="col-md-2 col-sm-4 noindex">
                                        <nav class="footer__nav">
                                            <p class="h3 footer__title">Follow us</p>
                                            <div class="socials footer__socials">
                                                <ul>
                                                    <li><a href="https://www.facebook.com/mbccGroup.NorthAmerica" title="Join us on Facebook" target="_blank"><i class="fa fa-facebook fa-icon-medium"></i></a></li>
                                                    <li><a href="https://www.linkedin.com/company/mbcc-group" title="Join us on LinkedIn" target="_blank"><i class="fa fa-linkedin fa-icon-medium"></i></a></li>
                                                    {/* <li><a href="https://www.twitter.com/sika" title="Join us on Twitter" target="_blank"><i class="ico-twitter"></i></a></li> */}
                                                    {/* <li><a href="https://www.youtube.com/user/sikaInternational" title="Join us on Youtube" target="_blank"><i class="ico-youtube"></i></a></li> */}
                                                    <li><a href="https://www.instagram.com/mbccgroup.northamerica/" title="Join us on Instagram" target="_blank"><i class="fa fa-instagram fa-icon-medium"></i></a></li>
                                                </ul>
                                            </div>
                                        </nav>
                                    </div>
                                    <div id="ctl00_ctl34_ctl00_logoContainer" class="col-md-6 col-sm-12 col-xs-12 noindex footer__logo-container">
                                        <p class="h3 footer__title no-border">SIKA CORPORATION</p>
                                        <p class="footer__text">201 Polito Avenue</p>
                                        <p class="footer__text">Lyndhurst New Jersey 07071</p>
                                        <p class="footer__text">United States of America</p>
                                        {/* <p class="footer__text footer__text--tel">Tel.:<a href="tel:+41584366800" class="cmp-footer-address__email">+41 58 436 6800</a></p> */}
                                        <p class="footer__text">phone +1 (201) 933-8800</p>
                                        <p class="footer__text">fax +1 (201) 804 1076</p>
                                        <img src={SikaLogo} id="ctl00_ctl34_ctl00_imgGroupLogo" class="footer__mbcc-group-logo" alt="Sika Company Logo" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div id="ctl00_ctl34_RowTrademarkText" class="row">
                        <div class="col-12">
                            <div class="footer__content">
                                <p class="footer__trademark">The trademark "Master Builders Solution" as well as related <a href="https://mbcc.sika.com/trademarklicense" class="footer__trademark-link">Admixture Systems product trademarks</a> are used under license from Construction Research &amp; Technology GmbH, Germany</p>
                            </div>
                        </div>
                    </div> */}
                </div>
                <div class="footer__bottom">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-12">
                                <div class="link-keeper">
                                    <ul id="ctl00_ctl34_ctl01_generalElementsContainer" class="list-links">
                                        <li>
                                            {/* <a href="https://mbcc.sika.com/en-us/footer/data-protection" target="_self" id="FotDataProtection">Data privacy</a> */}
                                            <a href="https://usa.sika.com/en/about-us/imprint.html" target="_self">Imprint</a>
                                        </li>
                                        <li>
                                            {/* <a href="https://mbcc.sika.com/en-us/footer/disclaimer" target="_self" id="FotDisclaimer">Disclaimer</a> */}
                                            <a href="https://usa.sika.com/en/about-us/legal-notice.html" target="_self">Legal Notice</a>
                                        </li>
                                        <li>
                                            {/* <a href="https://mbcc.sika.com/en-us/footer/credits" target="_self" id="FotCredits">Credits</a> */}
                                            <a href="https://usa.sika.com/en/about-us/california-supply-chains-act.html" target="_self">California Supply Chains Act</a>
                                        </li>
                                        <li>
                                            {/* <a href="javascript:usercentrics.toggleCornerModalIsVisible();" target="_self">Privacy settings</a> */}
                                            <a href="https://usa.sika.com/en/about-us/terms-conditions-of-sale.html" target="_self">Terms & Conditions of Sale</a>
                                        </li>
                                        <li>
                                            <a href="https://usa.sika.com/en/about-us/terms-conditions-of-purchase.html" target="_self">Terms & Conditions of Purchase</a>
                                        </li>
                                        <li>
                                            <a href="https://usa.sika.com/en/about-us/california-consumer-privacy-act.html" target="_self">CCPA Privacy</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        )
    }
}

export default Footer


{/* 
<div className="footer " id="footer">
                <div className="footer-container">
                    <div className="social">
                        <p>Follow Us</p>
                        <a href="https://www.facebook.com/MasterBuildersSolutionsNorthAmerica" target="_blank" rel="nofollow noopener noreferrer"><AiFillFacebook /></a>
                        <a href="https://www.linkedin.com/company/master-builders-solutions-/" target="_blank" rel="nofollow noopener noreferrer"><AiFillLinkedin /></a>
                        <a href="https://twitter.com/mbs_americas" target="_blank" rel="nofollow noopener noreferrer"><AiFillTwitterSquare /></a>
                        <a href="https://www.instagram.com/masterbuilderssolutions.na/" target="_blank" rel="nofollow noopener noreferrer"><AiOutlineInstagram /></a>
                    </div>
                    <div className="footer-content">
                        <p><a href="https://www.master-builders-solutions.com/en-us/footer/data-protection" target="_blank" rel="noopener noreferrer">Data Protection</a></p>
                        <span className="footer-divider">|</span>
                        <p><a href="mailto:bldgsystechsupport@mbcc-group.com">Contact Technical Service</a> </p>
                        <span className="footer-divider">|</span>
                        <p><a href="mailto:specs@mbcc-group.com">Contact our specifications experts</a></p> 
                        <span className="footer-divider">|</span>
                        <p><a href="https://www.master-builders-solutions.com/en-us">&copy; MBCC Group</a></p>
                    </div>
                </div>
            </div> */}







