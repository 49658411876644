import React, { Component } from "react"

import MasterBuildersLogo from '../images/Sika-MBS_Banner.svg'
import MasterBuildersLogoWhite from '../images/Sika-MBS_Banner.svg'
import SiteContext from "../context/SiteContext"

class Header extends Component {

  constructor(props) {
    super(props);
    this.state = {
      mobileMenuIsOpen: false,
      navIsFixed: false,
      navMobileIsFixed: false,
    }
  }

  handleNavScroll = () => {
    let navIsFixed = window.scrollY > 146;
    if (navIsFixed !== this.state.navIsFixed) {
      this.setState({ navIsFixed })
    }
    let navMobileIsFixed = window.scrollY > 85;
    if (navMobileIsFixed !== this.state.navMobileIsFixed) {
      this.setState({ navMobileIsFixed })
    }
  }

  componentDidMount() {
    document.addEventListener("scroll", this.handleNavScroll);
  }

  render() {

    let toggleMobileMenu = () => {
      this.setState(prevState => ({
        mobileMenuIsOpen: !prevState.mobileMenuIsOpen
      }));
    }

    return (
      <SiteContext.Consumer>
        {siteContext => (
          <header>
            <div className="wrapper">
              <a href="/" className="header__logo">
                <img className="logo-color" src={MasterBuildersLogo} alt="" />
                <img className="logo-white" src={MasterBuildersLogoWhite} alt="" />
              </a>
            </div>
            <div className={`header__nav ${this.state.mobileMenuIsOpen ? 'mobile-open' : 'mobile-closed'} ${this.state.navIsFixed ? 'fixed' : ''} ${this.state.navMobileIsFixed ? 'mobile-fixed' : ''}`} >
              <div className='wrapper wrapper--nav'>
                <a className="header__mobile-btn" label="mobile menu" onClick={toggleMobileMenu}>
                  <span className="header__burger"></span>
                </a>
                <nav className="header__nav-primary" role="navigation">
                  <a className='header__link link__flow' href='/masterflow'>MasterFlow Grout Selector Tool</a>
                  <a className='header__link link__emaco' href='/masteremaco'>MasterEmaco Concrete Repair Selector Tool</a>
                  <a className='header__link link__seal' href='/masterseal'>MasterSeal Traffic Coating Selector Tool</a>
                  <a className='header__link link__protect' href='/masterprotect'>MasterProtect ROI Calculator</a>
                  <a className='header__link link__top' href='/mastertop'>Performance Flooring Selector Tool</a>
                </nav>
              </div>
            </div>
          </header>
        )}
      </SiteContext.Consumer>
    )
  }
}

export default Header   